<template>
  <InvoicesEditingSection
        :invoice="invoice"
        :statuses="statuses"
        @save="save"
  />
</template>

<script>
  import InvoicesEditingSection from "./InvoicesCreationSection/InvoicesEditingSection";
  import {Invoices} from "../models/Invoices";
  import {invoicesMixin} from "../../../../../mixins/invoicesMixins/invoicesMixin";
  import {
    INVOICES_STATUS_PAYED,
    INVOICES_STATUS_DRAFT,
    INVOICES_STATUS_CANCELLED,
    INVOICES_TYPES,
    INVOICES_VARIANTS,
  } from "../../../../../staticData/staticVariables";

  export default {
    name: "InvoicesEditing",

    components:{
      InvoicesEditingSection,
    },

    mixins: [invoicesMixin],

    data() {
      return {
        invoice: new Invoices(),
        statuses: [
            INVOICES_STATUS_DRAFT,
            INVOICES_STATUS_PAYED,
            INVOICES_STATUS_CANCELLED
        ]
      }
    },

    mounted() {
      if (!this.isAdmin) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

      this.initInvoicesEdit()
    },

    methods: {
      initInvoicesEdit() {
          this.invoice.setId(this.$route.params.id)

          this.$store.dispatch('getInvoices', this.invoice.getId()).then((response) => {
            let item = this.getRespData(response)
            console.log(item);
            this.invoice.setItem(item, this)

            if (!this.checkInvoicePermissions()) {
              this.$router.push(this.$store.getters.GET_PATHS.notFound)
            }

            if (this.invoice.data.invoiceTypeId !== INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id) {
              this.statuses = this.statuses.filter((status) => status.name !== 'draft')
            }
            else {
              this.$store.dispatch('getInvoicesFiles', this.invoice.getId()).then((response) => {
                let respData = this.getRespData(response)
                let filesArray = []

                if (respData?.images) {
                  Object.keys(respData.images).forEach(imgItem => {
                    filesArray.push(respData.images[imgItem])
                  })
                }

                if (respData?.pdf) {
                  Object.keys(respData.pdf).forEach(pdfItem => {
                    filesArray.push(respData.pdf[pdfItem])
                  })
                }

                this.invoice.data.Files.setDownloadFiles(filesArray)

              })
            }
          }).catch(error => this.createErrorLog(error))


      },

      checkInvoicePermissions() {
        let hasPermission = true

        switch (this.invoice.data.invoiceVariant) {
          case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_NP.name: {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_NOVAPOSHTA)){
              hasPermission = false
            }
            break
          }
          case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_CDEK.name: {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_CDEK)){
              hasPermission = false
            }
            break
          }
          case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_FBM.name: {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_ORDERS)){
              hasPermission = false
            }
            break
          }
          case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_FBA.name: {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_FBA)){
              hasPermission = false
            }
            break
          }
          case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_INBOUND.name: {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_FBA_RETURN)){
              hasPermission = false
            }
            break
          }
          case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_EXPRESS.name: {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_ORDER_EXPRESS)){
              hasPermission = false
            }
            break
          }
          case INVOICES_VARIANTS.INVOICES_VARIANT_CONSOLIDATION.name: {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_CONSOLIDATION)){
              hasPermission = false
            }
            break
          }
          case INVOICES_VARIANTS.INVOICES_VARIANT_OTHER.name: {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.INVOICES_OTHER)){
              hasPermission = false
            }
            break
          }
          default:
            break
        }

        return hasPermission
      }
    }

  }
</script>

<style scoped>

</style>
