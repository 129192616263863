<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['invoices_Invoice',])"></div>
      <CardLeftBlock
        :name="$t('invoices_Invoice.localization_value.value')"
        :value="'#' + invoice.data.id"
        :backgroundImage="'shipments'"
      >
        <template slot="button">
          <div class="site-link brown text-decoration-underline mt-4">
            <div v-if="isAdmin && invoice.data.historyOfChanged"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('invoices', invoice.data.historyOfChanged)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['invoices_BackToInvoices',])"></div>
                <router-link :to="$store.getters.GET_PATHS.financeInvoices" class="order-create__head-back">
                  <LinkBack
                          :value="$t('invoices_BackToInvoices.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['invoices_editInvoice',])"></div>
              {{$t('invoices_editInvoice.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                    :validationUser="invoice.data.User.validation.userId"
                    :validationTranslateUser="invoice.data.User.validationTranslate.userId"
                    :userSelected="invoice.data.User.user"
                    :typeSelect="'users'"
                    :userEmail="true"
                    :disabled="true"
                    @changeUser="changeUser"
                />
              </div>
            </div>

            <div class="fragment">
              <div class="custom-row">
                <div class="custom-col custom-col--33 custom-col--md-100"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                    'invoices_Total',
                    ])"></div>
                  <DefaultInput
                      :label="$t('invoices_Total.localization_value.value')"
                      :disabled="true"
                      v-model="invoice.data.totalAmount"
                  />
                </div>
              </div>
            </div>

            <FieldsBlock
              :invoice="invoice"
              :statuses="statuses"
              :disableFieldsFlag="!(invoice.getInitialStatus().name === INVOICES_STATUS_DRAFT.name && invoice.getInvoiceTypeId() === INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id)"
            />
               <FilesBlock
                   v-if="invoice.getInvoiceTypeId() === INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id"
                   :invoice="invoice"
               />
            </div>
          </template>

           <template slot="footer">
             <div class="order-create__footer">

               <div class="order-create__footer-link"
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                 <div class="admin-edit" @click="editTranslate(['common_cancel',])"></div>
                 <router-link :to="$store.getters.GET_PATHS.financeInvoices"
                              class="site-link site-link--alt"
                 >
                   {{$t('common_cancel.localization_value.value')}}
                 </router-link>
               </div>

               <div class="order-create__footer-btn ml-auto"
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                 <div class="admin-edit" @click="editTranslate(['common_create'])"></div>

                 <MainButton
                         class="order-create__footer-btn-i"
                         :value="$t('common_save.localization_value.value')"
                         @click.native="$emit('save', true, 'open')"
                         v-bind:class="{'disabled-btn' : $store.getters.getInvoicesBtn}"
                 />
               </div>

             </div>
           </template>
         </CardRightBlock>

       </div>
     </div>
   </template>

   <script>
     import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
     import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
     import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
     import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
     import FieldsBlock from "../../../../InvoicesModule/components/chunks/FieldsBlock";
     import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
     import FilesBlock from "../../chunks/FilesBlock";
     import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
     import {INVOICES_STATUS_DRAFT, INVOICES_TYPES} from "../../../../../../staticData/staticVariables";

     export default {
       name: "InvoicesEditingSection",

       components: {
         DefaultInput,
         FilesBlock,
         UserSelect,
         FieldsBlock,
         CardLeftBlock,
         MainButton,
         CardRightBlock,
         LinkBack,
       },

       props: {
         invoice: Object,
         statuses: Array,
       },

       data() {
         return {
           INVOICES_STATUS_DRAFT: INVOICES_STATUS_DRAFT,
           INVOICES_TYPES: INVOICES_TYPES,
         }
       },

       methods: {
         changeUser(val) {
           this.invoice.data.User.setUser(val)
         },
       }
     }
   </script>

   <style lang="scss">

   </style>
